/* stylelint-disable prettier/prettier */
@import "../index";

html,
body {
    width: 100vw;
    height: 100%;
    font-size: 16px;
}

body {
    width: 100%;
    font-size: $defaultFontSize;
    margin: 0;
    font-family: $fontFamily !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: overlay;

    #root {
        height: 100%;
    }

    .app-container {
        height: 100%;

        .error-container {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2rem 0;
            height: 100%;
        }
    }
}

a {
    text-decoration: none;
}

@media only screen and (width <=800px) {
    html {
        font-size: 12px;
    }
}

@media only screen and (width >=801px) and (width <=1200px) {
    html {
        font-size: 14px;
    }
}

@media only screen and (width >=1201px) {
    html {
        font-size: 16px;
    }
}

.LayoutContainer {
    background-color: #ffffff;
    height: 100vh;
}

// :not(.btn-group-units) > .btn {
.btn {
    border-radius: 0 !important;
}

.btn-group-units {
    font-size: $subFont;
    margin: 1rem 0;

    .btn {
        padding-bottom: 0.25rem;
        font-size: 0.9375rem;
        line-height: 1.5625rem;
    }
}

.btn-outline-navy {
    --bs-btn-color: #051c2c;
    --bs-btn-border-color: #051c2c;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #051c2c;
    --bs-btn-hover-border-color: #051c2c;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #051c2c;
    --bs-btn-active-border-color: #051c2c;
    --bs-btn-active-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%);
    --bs-btn-disabled-color: #051c2c;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #051c2c;
    --bs-gradient: none;
}

.btn-toolbar {
    margin: 1rem 0;

    .btn-group-units {
        margin: 0;
    }

    .btn-group-units .btn {
        padding-bottom: 0.25rem;
        font-size: 0.9375rem;
        line-height: 1.5625rem;
    }
}

.btn-link {
    text-decoration: none !important;
}

.btn-link:hover {
    color: #0056b3 !important;
}

.btn-primary {
    background-color: #051c2c !important;
    border-color: #051c2c !important;
}

.Title {
    font-size: $xlFont;
    font-weight: $heavyFontWeight;
    margin-top: 1rem;
}

.jumpNavColumn {
    max-width: 150px;
    padding-top: 6.79rem;

    @media only screen and (width <=678px) {
        display: none;
    }
}

.chartSubTitle {
    font-size: $chartSubTitleFont;
    font-weight: $subFontWeight;
}

.subTitle {
    font-size: $defaultFontSize;
    font-weight: $defaultFontWeight;
}

input {
    background-color: inherit;
}

input:focus-visible {
    background-color: #ffffff;
}

input::placeholder {
    font-weight: 300;
}

.fw-bold {
    font-weight: $heavyFontWeight;
}

.ag-grid-helper-button-row {
    display: flex;
    justify-content: flex-end;
    padding: 7px 0 15px;
}

.grid-table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > div {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        > div {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    }
}

// For Safari
@media screen and (color-index >=0) and (resolution >=0) {
    .grid-table {
        // Required minimum height to overcome default ag grid height
        height: 250px !important;
        flex-grow: 1;
    }
}

.grid-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.ag-row button {
    font-family: $fontFamily !important;
    -webkit-font-smoothing: antialiased !important;
}

.progress-bar {
    background-color: #2251ff !important;
}

.d-flex > p {
    text-align: left;
    margin-bottom: 0;
    margin-top: 0.15rem;
}

.leaflet-container {
    width: 100%;
    height: 50vh;
    z-index: 1;
}

.markerClusterSmall {
    border-radius: 4.375rem;
    background-color: #051c2c;
    display: inline-block;
    margin-left: 0.625rem;
    font-size: 1.875rem;
    color: rgb(248 253 252);
    height: 3.125rem !important;
    width: 3.125rem !important;
    line-height: 0.75rem;
}

.markerClusterLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.leaflet-container .leaflet-bottom.leaflet-right {
    z-index: 2;
}

.leaflet-container .leaflet-pane {
    z-index: 1;
}

.ag-theme-alpine .ag-rich-select-list {
    width: 100%;
    height: fit-content;
    max-height: 6.25rem;
    overflow-y: scroll;
}

.ActionRow {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.popover {
    @include tooltip-style;

    height: fit-content;
}

.popover-top-bottom-arrow {
    max-width: 500px !important;
    padding: 0.3rem;

    .popover-arrow {
        left: 50% !important;
        transform: translateX(-50%) !important;
    }
}

.popover-left-right-arrow {
    padding: 0.5rem;

    .popover-arrow {
        top: 50% !important;
        transform: translateY(-50%) !important;
    }
}

.highcharts-root {
    font-family: $fontFamily;

    .highcharts-axis-labels {
        text {
            color: #666666 !important;
            fill: #666666 !important;
            font-size: 0.7em !important;
        }
    }

    .highcharts-axis-line {
        stroke: #ccd6eb;
    }

    .highcharts-lines {
        rect {
            height: 24px;
            width: 40px;
            text-align: center;
        }

        text {
            font-size: 0.8em;
        }
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.Toastify__toast {
    border-radius: 0;

    .toast-title {
        font-weight: bold;
        color: $White;
    }
}

.highcharts-tooltip span .tooltip-body {
    background-color: white;
    padding: 6px;
    z-index: 9999999 !important;
}

.bottom-border-tab-variant {
    .nav-tabs {
        width: fit-content;

        @media screen and (width <= 768px) {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;

            .nav-item {
                text-wrap: nowrap;
            }
        }

        .nav-link {
            @extend %heavy-text;

            font-weight: 400;
            color: $greyTextColor;
            font-size: 1.25rem;
            padding-bottom: 2px;
            padding-right: 0;
            margin-right: 1.25rem;
            padding-left: 0;
            border: none;

            &:hover {
                border-color: transparent;
            }

            &.active {
                color: #141721e0;
                border-bottom: 1px solid #141721e0;
                background-color: transparent;
            }

            // Modify size for mobile screens
            @media screen and (width <= 899px) {
                font-size: 0.8rem;
            }
        }
    }
}

.ag-measurement-container {
    display: none !important;
}

.w-fit-content {
    min-width: fit-content !important;
    max-width: fit-content !important;
}

.primary-color-60 {
    color: $primaryTextColor60;
}

.bg-opacity-40 {
    --bs-bg-opacity: 0.4;
}

.primary-selector__option.primary-selector__option--is-disabled {
    background-color: #cccccc !important;
}

.benchmarking-tip {
    width: 100%;
    height: 50px;
    padding-left: 2rem;
    display: flex;
    align-items: center;

    &.info {
        position: absolute;
        top: 4.5rem;
        background-color: #009eff1a;
        border: 1px solid #009eff;
        box-shadow:
            0 2px 4px -1px #14172114,
            0 4px 8px 0 #1417211f;
    }

    &.warning {
        background-color: #ffcc001a;
        border: 1px solid #ffcc00;
        margin-bottom: 15px;
        box-shadow:
            0 2px 4px -1px #14172114,
            0 4px 8px 0 #1417211f;
    }
}